import {Row, Col, Button} from 'react-bootstrap'
import React, { useEffect, useState, useMemo } from "react"
import axios from 'axios'
import moment from 'moment'

import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import ModalResponse from '../../components/modal-response'
import Pagination from '../../components/pagination'

import EditIcon from '../../assets/icon-pencil.svg'

let PageSize = 8;

const Home = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    fetch(
      `https://api.petersonslab.id/api/product`,
      {
        method: "GET",
      }
      )
      .then(res => res.json())
      .then(response => {
        setProducts(response);
        setCurrentData(response.slice(0, PageSize));
      })
      .catch(error => console.log(error));
      setCurrentPage(1);
  }, []);

  useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentData(products.slice(firstPageIndex, lastPageIndex));
  }, [currentPage]);

  const [publishedStatus, setPublishedStatus] = useState(true);

  const handleSelectBox = (e) => {
    const { id, checked } = e.target;
    if (id === "allSelect") {
      let tempproduct = currentData.map((product) => {
        product.is_published == 1 ? setPublishedStatus(true) : setPublishedStatus(false);
        return { ...product, isChecked: checked };
      });
      setCurrentData(tempproduct);
    } else {
      e.target.getAttribute('published') == 1 ? setPublishedStatus(true) : setPublishedStatus(false);
      let tempproduct = currentData.map((product) =>
        product.id == id ? { ...product, isChecked: checked } : product
      );
      setCurrentData(tempproduct);
    }
  };

  const [showResponse, setShowResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseEvent, setResponseEvent] = useState('');

  const handlePublish = (e) => {
      const publishBody = {};
      const selectedId = [];

      currentData.map((product) => {
          if(product.isChecked) {
            selectedId.push(product.id)
          }
      });
      
      if(e.target.getAttribute('action') == 'publish') {
        setResponseEvent('Published product');
        publishBody.data = selectedId;
        publishBody.published = 1;
      } else {
        setResponseEvent('Unpublished product');
        publishBody.data = selectedId;
        publishBody.published = 0;
      }

      console.log(publishBody);

      axios.patch('https://api.petersonslab.id/api/product/publish', publishBody)
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/products';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
  };

  const handleDelete = () => {
    const selectedId = [];
    const deleteBody = {};
    
    currentData.map((product) => {
        if(product.isChecked) {
          selectedId.push(product.id)
        }
    });

    setResponseEvent('Deleted Product');
    deleteBody.data = selectedId;
    axios.post('https://api.petersonslab.id/api/product/delete', deleteBody)
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/products';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
  };
  
  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event={responseEvent} response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Product Entry' user='Diana Prince'/>
        <div className='dashboard-content'>
          <Row>
            <Col><h4 className='content-header'>Product Entry</h4></Col>
              {currentData.some((product) => product?.isChecked)? (
                <Col className='content-header-btn-action'>
                {
                  publishedStatus ? (
                    <Button variant='secondary' className='me-3 header-btn-action-publish' onClick={handlePublish} action='unpublish'>Unpublish</Button>
                  ) : (
                    <Button variant='secondary' className='me-3 header-btn-action-publish' onClick={handlePublish} action='publish'>Publish</Button>
                  )
                }
                <Button variant='warning' className='me-3 header-btn-action-change' onClick={handleDelete}>Delete</Button>
                </Col>
              ): (
                <Col className='content-header-btn-action'>
                  <a href='/products/add'><Button variant='warning' className='me-3 header-btn-action-change'>Add</Button></a>
                </Col>
              )}
          </Row>
        </div>
        <div className='row-data'>
          <div className='row-header mb-4'>
            <Row>
                <Col md={1}><input
                type="checkbox"
                className={`${currentData.every((product) => product?.isChecked)? 'checkbox-checked' : '' } form-check-input`}
                id="allSelect"
                checked={!currentData.some((product) => product?.isChecked !== true)}
                onChange={handleSelectBox}
                /></Col>
                <Col md={1}>ID</Col>
                <Col>Name</Col>
                <Col>Created Date</Col>
                <Col>Last Modified</Col>
                <Col md={1}>Status</Col>
                <Col md={1} className='row-btn-section'>&nbsp;</Col>
            </Row>
          </div>
          <div className='row-list'>
          {currentData.map((product, index) => (
            <Row className={`${product?.isChecked ? 'row-checked' : ''} mb-3 row-list-per-data`}>
              <Col md={1}><input
              type="checkbox"
              className={`${product?.isChecked ? 'checkbox-checked' : ''} form-check-input checkbox-per-data`}
              id={product.id}
              published={product.is_published}
              checked={product?.isChecked || false}
              onChange={handleSelectBox}
              key={index}
              /></Col>
              <Col md={1}>{product.id}</Col>
              <Col>{product.name}</Col>
              <Col>{moment(product.created_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col>{moment(product.updated_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col md={1}>{
              product.is_published == 1 ? 'Published' : 'Unpublished'
              }</Col>
              <Col md={1} className='row-btn-section'><a href={'/products/edit/' + product.id}><img src={EditIcon} /></a></Col>
            </Row>
          ))}
          </div>
        </div>
        <Pagination
          className="pagination-bar pb-4 justify-content-end pe-4"
          currentPage={currentPage}
          totalCount={products.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </>
  );
}

export default Home;
