import { Container, Form, Button } from 'react-bootstrap';

import mainlogo from '../../assets/main-logo-dark.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

import { useState, useEffect } from 'react'

function Login({ setToken }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    
    const formBody = {
      'email' : email,
      'password': password
    }

    axios.post('https://api.petersonslab.id/api/login/member', formBody)
      .then(response => {
        sessionStorage.setItem('user_data', JSON.stringify(response.data.user_data));
        var token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        setToken(token);
        window.location = '/banner';
      })
      .catch(errors => {
      })
  }

  useEffect(() => {
    if(sessionStorage.getItem('user_data') != "" && sessionStorage.getItem('user_data') != null) {
      var userdata = JSON.parse(sessionStorage.getItem('user_data'));
      if(userdata.name != "") {
        window.location = '/banner';
      }
    }
  }, []);

  return (
      <div className='loginpage'>
        <img src={mainlogo} />
        <Container className='loginbox'>
          <div className='loginheader'>
            <h1>Login</h1>
            <p>Login using your credential</p>
          </div>
          <Form className='loginform' onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="name@example.com" className='logininput' name='email' onChange={e => setEmail(e.target.value)}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="password" className='logininput' name='password' onChange={e => setPassword(e.target.value)}/>
            </Form.Group>
            <Button type='submit' className='loginbtn' variant='dark'>Login</Button>
          </Form>
        </Container>
      </div>
  );
}

export default Login;
