import Sidebar from '../../../components/sidebar'
import Header from '../../../components/header'
import {Row, Col, Button, Form} from 'react-bootstrap'

import IconPlus from '../../../assets/icon-plus.svg'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ModalResponse from '../../../components/modal-response'
import imageCompression from 'browser-image-compression';

import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

const Edit = () => {
    const [data, setData] = useState({});
    const { id } = useParams();
    const[categories, setCategories] = useState([]);
    const[formType,setFormType] = useState('add');

    useEffect(() => {
        if(id!=null&&id>0&&id!='undefined'){
            setFormType('edit')
            fetch(
                `https://api.petersonslab.id/api/article/post/`+id,
                {
                  method: "GET",
                }
            )
            .then(res => res.json())
            .then(response => {
                setData(response);
                quill.clipboard.dangerouslyPasteHTML(response.content);
            })
            .catch(error => console.log(error));
        }
        fetch(
            `https://api.petersonslab.id/api/article/category`,
            {
            method: "GET",
            }
        )
        .then(res => res.json())
        .then(response => {
          setCategories(response);
        })
        .catch(error => console.log(error));
    }, []);

    const [formBody, setformBody] = useState({});

    const handleFormChange = (e) => {
        const updatedFormBody = {[e.target.name] : e.target.value}  
        setformBody( formBody => ({
            ...formBody,
            ...updatedFormBody,
        }));
        if(formType=='edit'){
            const updatedFormBody = {[e.target.name] : e.target.value}  
            setData( data => ({
            ...data,
            ...updatedFormBody,
            }));
        }
    }

    const [files, setFiles] = useState([]);

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    const handleUploadImage = async (e) => {
        var base64files = '';
  
        const image = e.target.files[0];
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(image, options);
          var fileUrl = URL.createObjectURL(image)
          setFiles(fileUrl);
  
          base64files = await convertToBase64(compressedFile);
        } catch (error) {
          console.log(error);
        }
        
        setformBody( formBody => ({
          ...formBody,
          ...{image : base64files},
        }));
    }

    const [showResponse, setShowResponse] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handleFormSubmit = (e) => {
      e.preventDefault();
      setSubmitting(true);
        fetch('https://api.petersonslab.id/api/article/post', {
          method: 'POST',
          body: JSON.stringify(formBody),
          headers: {
            'Content-Type' : 'application/json'
          }
        })
        .then( res => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
              window.location = '/article/post';
          }, 1000);
        })
        .catch( e => {
          setShowResponse(true);
          setResponseMessage('error');
        })
    };

    const[submitting, setSubmitting] = useState(false);

    const { quill, quillRef } = useQuill();

    useEffect(() => {
        if (quill) {
        quill.on('text-change', (delta, oldDelta, source) => {
            setformBody( formBody => ({
                ...formBody,
                ...{content : quill.root.innerHTML},
            }));
        });
        }
    }, [quill]);

    return (
        <>
        <Sidebar />
        <div className='main-dashboard'>
            {
                showResponse ? (
                    <ModalResponse event={formType == 'edit' ? ('Posts Edited'):('Posts Added')} response={responseMessage}/>
                ) : (
                    <div></div>
                )
            }
            <Header page={formType == 'edit' ? ('Edit Post'):('Add New Post')} user='Diana Prince'/>
            <Row className='dashboard-content'>
            <Col md={12}>
                <Form onSubmit={handleFormSubmit}>
                <Row>
                    <Col>
                    <h4 className='content-header'>{formType == 'edit' ? ('Edit Article Post'):('Add New Article Post')}</h4>
                    </Col>
                    <Col className='content-header-btn-action'>
                    <Button type='submit' variant='warning' className='header-btn-action-change' disabled={submitting}>{submitting ? 'Saving...' : 'Save Changes'}</Button>
                    </Col>
                </Row>
                <div className='content-form'>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                        <Form.Label className='content-form-label'>Category</Form.Label>
                        <Form.Select aria-label="Default select example" name='id_category' onChange={handleFormChange} value={formType == 'edit' ? (data.id_category):(null)}>
                            <option>Select Category</option>
                            {
                            categories.map((category, index) => (
                                <option value={category.id} key={index}>{category.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                        <Form.Label className='content-form-label'>Title</Form.Label>
                        <Form.Control type="text" placeholder="Add Posts Title" name='title' onChange={handleFormChange} value={formType == 'edit' ? (data.title):(null)}/>
                    </Form.Group>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                        <Form.Label className='content-form-label'>Subtitle</Form.Label>
                        <Form.Control type="text" placeholder="Add Posts Subtitle" name='subtitle' onChange={handleFormChange} value={formType == 'edit' ? (data.subtitle):(null)}/>
                    </Form.Group>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                        <Form.Label className='content-form-label'>Upload Banner<span>(Max File Size: 2MB)</span></Form.Label>
                        <label htmlFor='form-image' className='label-home-banner'>
                            {
                                files != '' ? (
                                    <img src={files} alt='...' className='label-home-banner-img'/>
                                )
                                : formType == 'edit' ? (
                                    <img src={'https://api.petersonslab.id/article/'+data.image} alt='...' className='label-home-banner-img'/>
                                ):(
                                    <img src={IconPlus} className='label-home-banner-icon'/>
                                )
                            }
                        </label>
                        <input id='form-image' type="file" hidden onChange={handleUploadImage}/>
                    </Form.Group>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                        <Form.Label className='content-form-label'>Content</Form.Label>
                        <div style={{ width: '100%', height: 300 }}>
                            <div ref={quillRef} />
                        </div>
                    </Form.Group>
                </div>
                </Form>
            </Col>
            </Row>
        </div>
        </>
    );
}

export default Edit;