import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Login,
  Banner,
  Category,
  Product,
  Member,
  CategoryEdit,
  Newsletter,
  Transactions,
  MemberEdit,
  ProductEdit,
  CategoryAdd,
  MemberAdd,
  ProductAdd,
  PromoCard,
  PromoBanner,
  Users,
  SocialMedia,
  BannerAdd,
  BannerEdit,
  ArticleCategory,
  ArticlePost,
  ArticlePostForm
} from "./pages";
import { useEffect, useState } from 'react';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

function setTokenSession(userToken) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
}

function getTokenSession() {
  const savedToken = sessionStorage.getItem('token');
  return savedToken
}

function App() {
  const token = getTokenSession();

  if(!token) {
    return <Login setToken={setTokenSession}/>
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/banner" element={<Banner />} />
          <Route path="/banner/add" element={<BannerAdd />} />
          <Route path="/banner/edit/:id" element={<BannerEdit />} />
          <Route path="/category" element={<Category />} />
          <Route path="/category/add" element={<CategoryAdd />} />
          <Route path="/category/edit/:id" element={<CategoryEdit />} />
          <Route path="/products" element={<Product />} />
          <Route path="/products/add" element={<ProductAdd />} />
          <Route path="/products/edit/:id" element={<ProductEdit />} />
          <Route path="/member" element={<Member />} />
          <Route path="/member/add" element={<MemberAdd />} />
          <Route path="/member/edit/:id" element={<MemberEdit />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/promobanner" element={<PromoBanner />} />
          <Route path="/promocard" element={<PromoCard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/socialmedia" element={<SocialMedia />} />
          <Route path="/article/category" element={<ArticleCategory />} />
          <Route path="/article/post" element={<ArticlePost />} />
          <Route path="/article/post/add" element={<ArticlePostForm />} />
          <Route path="/article/post/edit/:id" element={<ArticlePostForm />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
