export const ContentManagement = [
    {
        title : "Main Banner",
        link: "/banner"
    },
    {
        title : "Product Category",
        link: "/category"
    },
    {
        title : "Product Entry",
        link: "/products"
    },
    {
        title : "Promo Banner",
        link: "/promobanner"
    },
    {
        title : "Promo Card",
        link: "/promocard"
    },
    {
        title : "Social Media Images",
        link: "/socialmedia"
    },
]

export const UserManagement = [
    {
        title : "Member",
        link: "/member"
    },
    {
        title : "Newsletter",
        link: "/newsletter"
    },
    {
        title : "Users",
        link: "/users"
    },
]

export const TransactionManagement = [
    {
        title : "Transactions",
        link: "/transactions"
    }
]

export const ArticleManagement = [
    {
        title : "Posts",
        link: "/article/post"
    },
    {
        title : "Category",
        link: "/article/category"
    }
]