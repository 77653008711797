import React from 'react'
import {ContentManagement, UserManagement, TransactionManagement, ArticleManagement} from './data'
import {Container} from 'react-bootstrap'
import MainLogo from '../../assets/main-logo-light.svg'
import LogoutIcon from '../../assets/icon-logout.svg'

const Home = () => {
  const handleLogout = () => {
    sessionStorage.clear();
    window.location = '/';
  }

  return (
    <>
      <div className='sidebar'>
          <Container>
            <div className='sidebarheader'>
                <img src={MainLogo} />
                <p>Content Management System</p>
            </div>
            <div className='sidebar-menu-list'>
              <div className='sidebar-content'>
                <p className='sidebar-subtitle'>Content Management</p>
                <ul className='sidebarlist'>
                { ContentManagement.map((val, key)=>{
                    return(
                        <li 
                        key={key} 
                        onClick={()=>{window.location.pathname = val.link}}
                        className='sidebarrow'
                        >
                            {" "}
                            <div>
                                {val.title}
                                <span className={window.location.pathname.includes(val.link ) ? 'menuactive' : ''}></span>
                            </div>
                        </li>
                    );
                }) }
                </ul>
              </div>
              <div className='sidebar-transactions'>
                <p className='sidebar-subtitle'>Transactions Management</p>
                <ul className='sidebarlist'>
                { TransactionManagement.map((val, key)=>{
                    return(
                        <li 
                        key={key} 
                        onClick={()=>{window.location.pathname = val.link}}
                        className='sidebarrow'
                        >
                            {" "}
                            <div>
                                {val.title}
                                <span className={window.location.pathname.includes(val.link ) ? 'menuactive' : ''}></span>
                            </div>
                        </li>
                    );
                }) }
                </ul>
              </div>
              <div className='sidebar-user'>
                <p className='sidebar-subtitle'>User Management</p>
                <ul className='sidebarlist'>
                { UserManagement.map((val, key)=>{
                    return(
                        <li 
                        key={key} 
                        onClick={()=>{window.location.pathname = val.link}}
                        className='sidebarrow'
                        >
                            {" "}
                            <div>
                                {val.title}
                                <span className={window.location.pathname.includes(val.link ) ? 'menuactive' : ''}></span>
                            </div>
                        </li>
                    );
                }) }
                </ul>
              </div>
              <div className='sidebar-user'>
                <p className='sidebar-subtitle'>Article Management</p>
                <ul className='sidebarlist'>
                { ArticleManagement.map((val, key)=>{
                    return(
                        <li 
                        key={key} 
                        onClick={()=>{window.location.pathname = val.link}}
                        className='sidebarrow'
                        >
                            {" "}
                            <div>
                                {val.title}
                                <span className={window.location.pathname.includes(val.link ) ? 'menuactive' : ''}></span>
                            </div>
                        </li>
                    );
                }) }
                </ul>
              </div>
            </div>
            <div className='sidebarfooter'>
                <li 
                onClick={handleLogout}
                className='logout'
                >
                    <img src={LogoutIcon} />
                    <span>Logout</span>
                </li>
            </div>
          </Container>
      </div>
    </>
  );
}

export default Home;
