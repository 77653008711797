import React, { useEffect, useState, useMemo } from "react";
import {Row, Col, Button, Modal, Form} from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import { DateRangePicker } from 'react-date-range';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import Pagination from '../../components/pagination'
import ModalResponse from '../../components/modal-response'

import EditIcon from '../../assets/icon-pencil.svg'
import moment from 'moment'

let PageSize = 8;

const Home = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [transactions, setTransaction] = useState([]);
  const [currentTransactionsData, setCurrentTransactionsData] = useState([]);

  useEffect(() => {
    var url = "https://api.petersonslab.id/api/transactions/detail/all";
    axios.get(url)
    .then(response => {  
      setTransaction(response.data);
      setCurrentTransactionsData(response.data.slice(0, PageSize));
    })
    .catch(error => console.log(error));
    setCurrentPage(1);
  }, []);

  const userData = JSON.parse(sessionStorage.getItem('user_data'));

  useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentTransactionsData(transactions.slice(firstPageIndex, lastPageIndex));
  }, [currentPage]);

  const[filterApplied, setFilterApplied] = useState({});

  const handleFilterBarChange = (e) => {
    var filters = filterApplied
    if(e.target.value == '' || e.target.value == '0') {
      delete filters[e.target.name]
      setFilterApplied(filterApplied => {
        const filter = {...filterApplied};
        delete filter[e.target.name];
        return filter;
      });

      handleApplyFilter(filters)
      return
    }

    filters[e.target.name] = e.target.value
    const updatedfilter = {[e.target.name] : e.target.value}  
    setFilterApplied( filterApplied => ({
        ...filterApplied,
        ...updatedfilter,
    }));
    handleApplyFilter(filters)
  }

  const handleApplyFilter = (params) => {
    var url = "https://api.petersonslab.id/api/transactions/detail/all";
    axios.get(url, {
      params : params
    })
    .then(response => {  
      setTransaction(response.data);
      setCurrentTransactionsData(response.data.slice(0, PageSize));
      setCurrentPage(1);
    })
    .catch(error => console.log(error));
  }

  const [formBody, setformBody] = useState({});
  const handleFormChange = (e) => {
    const updatedFormBody = {[e.target.name] : e.target.value}  
    setformBody( formBody => ({
        ...formBody,
        ...updatedFormBody,
    }));
  }

  const [showResi, setShowResi] = useState(false);
  const handleCloseResi = () => setShowResi(false);

  const handleShowResi = (e) => {
    var id = e.currentTarget.getAttribute("data-id");
    const updatedFormBody = {["id"] : id}  
    setformBody( formBody => ({
        ...formBody,
        ...updatedFormBody,
    }));
    setShowResi(true);
  }

  const handleSubmitResi = (e) => {
    setResponseEvent('Receipt Added');
    e.preventDefault();
    console.log(formBody);
    axios.patch('https://api.petersonslab.id/api/transactions/receipt', formBody)
      .then(response => {
          setShowResponse(true);
          setResponseMessage('success');
          window.setTimeout(function() {
             window.location = '/transactions';
          }, 700);
      })
      .catch(errors => {
          setShowResponse(true);
          setResponseMessage('error');
      })
  }

  const [showDetails, setShowDetails] = useState(false);
  const [dataDetails, setDataDetails] = useState({});
  const handleCloseDetails = () => setShowDetails(false);

  const handleShowDetails = (e) => { 
    var id = e.currentTarget.getAttribute("data-id");
    transactions.map((tr, index) => {
      if(tr.id.toString() == id) {
        setDataDetails(tr);
      }
    })
    setShowDetails(true);
  }

  const [showResponse, setShowResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseEvent, setResponseEvent] = useState('');

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const handleCompleteTransactions = (e) => {
    setResponseEvent('Transaction Completed');
    var formBody = {
      id: e.currentTarget.getAttribute('data-id')
    }
    axios.post('https://api.petersonslab.id/api/transactions/complete', formBody)
    .then(response => {
        setShowResponse(true);
        setResponseMessage('success');
        window.setTimeout(function() {
          window.location = '/transactions';
        }, 700);
    })
    .catch(errors => {
        setShowResponse(true);
        setResponseMessage('error');
        console.log(errors);
    })
  }

  const sortAsc = (arr, field) => {
    if (field == 'orderDate'){
      return arr.sort((a, b) => {
        let ax = new Date(a[field]);
        let bx = new Date(b[field]);
        if (ax > bx) { return 1; }
        if (ax < bx) { return -1; }
        return 0;
      })
    }

    let fields = [];
    if (field.includes(".")){
      fields = field.split(".");
    }

    if (fields.length > 0){
      return arr.sort((a, b) => {
        if (a[fields[0]][fields[1]] > b[fields[0]][fields[1]]) { return 1; }
        if (b[fields[0]][fields[1]] > a[fields[0]][fields[1]]) { return -1; }
        return 0;
      })
    }

    return arr.sort((a, b) => {
      if (a[field] > b[field]) { return 1; }
      if (b[field] > a[field]) { return -1; }
      return 0;
    })
  }
  
  const sortDesc = (arr, field) => {
    if (field == 'orderDate'){
      return arr.sort((a, b) => {
        let ax = new Date(a[field]);
        let bx = new Date(b[field]);
        if (ax > bx) { return -1; }
        if (ax < bx) { return 1; }
        return 0;
      })
    }

    let fields = [];
    if (field.includes(".")){
      fields = field.split(".");
    }

    if (fields.length > 0){
      return arr.sort((a, b) => {
        if (a[fields[0]][fields[1]] > b[fields[0]][fields[1]]) { return -1; }
        if (b[fields[0]][fields[1]] > a[fields[0]][fields[1]]) { return 1; }
        return 0;
      })
    }

    return arr.sort((a, b) => {
      if (a[field] > b[field]) { return -1; }
      if (b[field] > a[field]) { return 1; }
      return 0;
    })
  }

  const[fieldSort, setFieldSort] = useState('');
  const[orderSort, setOrderSort] = useState('');

  const handleSortData = (e) => {
    let key = e.currentTarget.getAttribute('data-key');
    if(key != fieldSort){
      setFieldSort(key);
      setOrderSort('asc');
      setTransaction(sortAsc(transactions, key));
    }
    if(key == fieldSort && orderSort == 'asc'){
      setFieldSort(key);
      setOrderSort('desc');
      setTransaction(sortDesc(transactions, key));
    }
    if(key == fieldSort && orderSort == 'desc'){
      setFieldSort('');
      setOrderSort('');
      setTransaction(sortAsc(transactions, 'id'));
    }
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentTransactionsData(transactions.slice(firstPageIndex, lastPageIndex));
  }

  const[dateRange,setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  const toIsoStringWithTimeZone = (date) => {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
            return (num < 10 ? '0' : '') + num;
        };
  
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
  }

  const handleChangeDate = (ranges) => {
    const updatedDateRange = {
      startDate :ranges.selection.startDate,
      endDate : ranges.selection.endDate
    }  
    setDateRange( dateRange => ({
        ...dateRange,
        ...updatedDateRange,
    }));
    
    var filters = filterApplied
    var startDate = toIsoStringWithTimeZone(ranges.selection.startDate).split('T')[0]
    var endDate = toIsoStringWithTimeZone(ranges.selection.endDate).split('T')[0]
    filters["dateStart"] = startDate
    filters["dateEnd"] = endDate
    const updatedfilter = {
      dateStart : startDate,
      dateEnd : endDate
    }  
    setFilterApplied( filterApplied => ({
        ...filterApplied,
        ...updatedfilter,
    }));
    handleApplyFilter(filters)
  }

  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event={responseEvent} response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Transactions' user={userData.name}/>
        <Row className="dashboard-filter-bar">
          <Col md={1}></Col>
          <Col>
            <Form.Control name='name' type="text" placeholder="Search" className="search-bar" onChange={handleFilterBarChange}/>
          </Col>
          <Col>
            <Form.Select name='statuses' aria-label="Default select example" className="search-bar" onChange={handleFilterBarChange}>
              <option disabled>select status</option>
              <option value="">All</option>
              <option value="1">Pending Payment</option>
              <option value="2">Payment Received</option>
              <option value="3">In Delivery</option>
              <option value="4">Completed</option>
              <option value="5">Canceled</option>
            </Form.Select>
          </Col>
          <Col></Col>
          <Col>
            <Dropdown>
              <Dropdown.Toggle className="search-bar search-date" id="dropdown-basic">
                {
                  filterApplied["dateStart"] == null || filterApplied["dateEnd"] == null ? (
                    <p className="search-date-text">Filter by Date</p>
                  ):(
                    <p className="search-date-text">{moment(filterApplied["dateStart"]).format('MMM Do YY')} - {moment(filterApplied["dateEnd"]).format('MMM Do YY')}</p>
                  )
                }
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <DateRangePicker
                  ranges={[dateRange]}
                  onChange={handleChangeDate}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={3}></Col>
        </Row>
        <div className='row-data'>
          <div className='row-header mb-4'>
            <Row>
                <Col md={1}>No</Col>
                <Col className="row-header-filter" data-key={'user.name'} onClick={handleSortData}>
                  Name
                  {fieldSort=='user.name'&&orderSort=='asc'? <FontAwesomeIcon icon={faAngleDown} className='row-header-filter-icon' />: null }
                  {fieldSort=='user.name'&&orderSort=='desc'? <FontAwesomeIcon icon={faAngleUp} className='row-header-filter-icon' />: null }
                </Col>
                <Col className="row-header-filter" data-key={'status'} onClick={handleSortData}>
                  Status
                  {fieldSort=='status'&&orderSort=='asc'? <FontAwesomeIcon icon={faAngleDown} className='row-header-filter-icon' />: null }
                  {fieldSort=='status'&&orderSort=='desc'? <FontAwesomeIcon icon={faAngleUp} className='row-header-filter-icon' />: null }
                </Col>
                <Col className="row-header-filter" data-key={'total'} onClick={handleSortData}>
                  Total
                  {fieldSort=='total'&&orderSort=='asc'? <FontAwesomeIcon icon={faAngleDown} className='row-header-filter-icon' />: null }
                  {fieldSort=='total'&&orderSort=='desc'? <FontAwesomeIcon icon={faAngleUp} className='row-header-filter-icon' />: null }</Col>
                <Col className="row-header-filter" data-key={'orderDate'} onClick={handleSortData}>
                  Created Date
                  {fieldSort=='orderDate'&&orderSort=='asc'? <FontAwesomeIcon icon={faAngleDown} className='row-header-filter-icon' />: null }
                  {fieldSort=='orderDate'&&orderSort=='desc'? <FontAwesomeIcon icon={faAngleUp} className='row-header-filter-icon' />: null }
                </Col>
                <Col md={3} className='row-btn-section'>&nbsp;</Col>
            </Row>
          </div>
          <div className='row-list'>
            {currentTransactionsData.map((tr, index) => (
              <Row key={index} className='mb-3 row-list-per-data'>
                <Col md={1}>{index+1}</Col>
                <Col className='row-tr-name'>{tr.user.name}</Col>
                <Col>{tr.status}</Col>
                <Col>Rp. {numberWithCommas(tr.total)}</Col>
                <Col>{tr.orderDate}</Col>
                <Col md={3} className='row-btn-section-tr'>
                  <Button variant='light' className='row-btn-details' data-id={tr.id} onClick={handleShowDetails}>View Details</Button>
                  {
                    tr.status_id != "5" && tr.status_id != "4" && tr.status_id != "3" ? (
                      <Button variant='light' className='row-btn-details' data-id={tr.id} onClick={handleShowResi}><img src={EditIcon} /></Button>
                    ) : (
                      <></>
                    )
                  }
                  {
                    tr.status_id == "3" ? (
                      <Button variant='warning' data-id={tr.id} className='btn-action-transactions' onClick={handleCompleteTransactions}>Complete</Button>
                    ) : (
                      <></>
                    )
                  }
                </Col>
              </Row>
            ))}
          </div>
        </div>
        <Pagination
          className="pagination-bar pb-4 justify-content-end pe-4"
          currentPage={currentPage}
          totalCount={transactions.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>

      <Modal show={showResi} onHide={handleCloseResi}>
        <Form onSubmit={handleSubmitResi}>
          <Modal.Header closeButton>
            <Modal.Title>Courier Receipt</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Shipment Vendor</Form.Label>
                <Form.Control name='vendor' type="text" placeholder="Input Shipment Vendor" onChange={handleFormChange} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Receipt Number</Form.Label>
                <Form.Control name='receipt' type="text" placeholder="Input Shipment Receipt Number" onChange={handleFormChange} />
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className='header-btn-action-publish' onClick={handleCloseResi}>
              Close
            </Button>
            <Button type='submit' variant="warning" className='header-btn-action-change'>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showDetails} onHide={handleCloseDetails}>
        <Modal.Header closeButton>
          <Modal.Title>Transactions Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            dataDetails && Object.keys(dataDetails).length === 0 ? (
              <></>
            ) : (
              <div>
                <div className='tr-details-header'>
                    <Row>
                      <Col>
                        <p>Username : </p>
                      </Col>
                      <Col>
                        <p className='tr-details-header-text'>{dataDetails.user.name}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>Order Date : </p>
                      </Col>
                      <Col>
                        <p className='tr-details-header-text'>{dataDetails.orderDate}</p>
                      </Col>
                    </Row>
                </div>
                <div className='tr-details-body'>
                  {
                    dataDetails.product.map((product, index) => (
                      <Row key={index} className='tr-details-productsec'>
                        <Col md={3}>
                          <img src={`https://api.petersonslab.id/product/`+product.image} className='tr-details-product-img' />
                        </Col>
                        <Col md={9}>
                          <Row>
                            <Col md={8}>
                              <div className='tr-details-product-desc'>
                                <p className='tr-details-product-kategori'>{product.category}</p>
                                <p className='tr-details-product-name'>{product.name}</p>
                                <p className='tr-details-product-size'>{product.size}</p>
                                <div className='tr-details-product-pricesec'>
                                  <p className='tr-details-product-pricestrike'>Rp. {numberWithCommas(product.pricestrike)}</p>
                                  <p className='tr-details-product-price'>Rp. {numberWithCommas(product.price)}</p>
                                </div>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div variant='secondary' className='tr-details-product-qtysec'>
                                <span className='tr-details-product-qty'>Qty : {product.qty}</span>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={8}>
                              <p className='tr-details-product-subtotal'>Subtotal :</p>
                            </Col>
                            <Col md={4}>
                              <p className='tr-details-product-subtotal-value'>Rp. {numberWithCommas(product.price)}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))
                  }
                  <div className='tr-details-subtotalsec'>
                    <Row>
                      <Col>
                        <p>Subtotal : </p>
                      </Col>
                      <Col>
                        <p className='tr-details-header-text'>Rp. {numberWithCommas(dataDetails.total)}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>Delivery Fee : </p>
                      </Col>
                      <Col>
                        {dataDetails.delivery != "0" ? (
                          <p className='tr-details-header-text'>Rp. {numberWithCommas(dataDetails.delivery)}</p>
                        ) : (
                          <p className='tr-details-header-text'>Not Choosen Yet</p>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className='tr-details-totalsec'>
                    <Row>
                      <Col>
                        <p>Total : </p>
                      </Col>
                      <Col>
                        <p className='tr-details-total-text'>Rp. {numberWithCommas(parseInt(dataDetails.total)+parseInt(dataDetails.delivery))}</p>
                      </Col>
                    </Row>
                  </div>
                  <div className='tr-details-deliveryaddresssec'>
                    <p className='tr-details-address-header'>Delivery Method</p>
                    {dataDetails.delivery == "0" ? ( 
                      <p className='tr-details-address-notchoose'>Not Choosen Yet</p>
                      ) : (
                        <div>
                          <Row>
                            <Col md={4}>
                              <p>Courier : </p>
                            </Col>
                            <Col>
                              <p className='tr-details-address-text'>{dataDetails.shipment_vendor}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <p>Tracking Number :</p>
                            </Col>
                            <Col>
                              {
                                dataDetails.tracking_number != "" ? (
                                  <p className='tr-details-address-text'>{dataDetails.tracking_number}</p>
                                ) : (
                                  <p className='tr-details-address-text'>Not Added</p>
                                )
                              }
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                            <p>Address :</p>
                            </Col>
                            <Col>
                              <p className='tr-details-address-text'>{dataDetails.address.nama_penerima}</p>
                              <p className='tr-details-address-text tr-details-address-detail'>{dataDetails.address.phone}</p>
                              <p className='tr-details-address-text tr-details-address-detail'>{dataDetails.address.details}. {dataDetails.address.zipcode}</p>
                            </Col>
                          </Row>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )
          }
          
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Home;
