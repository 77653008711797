import React, { useEffect, useState } from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import UserIcon from '../../assets/icon-user.svg'
import Separator from '../../assets/arrow-page-separator.svg'

const Home = ({page}) => {
    const[username, setUsername] = useState('');

    useEffect(() => {
        var userdata = JSON.parse(sessionStorage.getItem('user_data'));
        setUsername(userdata.name);
      }, []);
  return (
    <>
        <div className='header'>
            <Row>
                <Col className='header-page'>
                    <p>
                        <span className='header-page-dashboard'>Dashboard</span> 
                        <span><img src={Separator}/></span>
                        <span className='header-page-page'>{page}</span>
                    </p>
                </Col>
                <Col className='header-user'>
                    <Row>
                        <Col md={10}>
                            <p className='header-user-welcome'>welcome,</p>
                            <p className='header-user-name'>{username}</p>
                        </Col>
                        <Col md={2} className='header-user-icon-section'>
                            <img src={UserIcon} className='header-user-icon'/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className='header-border'></div>
        </div>
    </>
  );
}

export default Home;
