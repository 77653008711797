import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import Status from '../../components/status'
import ModalResponse from '../../components/modal-response'

import {Row, Col, Button, Form} from 'react-bootstrap'
import axios from 'axios'

import { useEffect, useState } from 'react'

const Home = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    fetch(
      `https://api.petersonslab.id/api/promo-card`,
      {
        method: "GET",
      }
      )
      .then(res => res.json())
      .then(response => {
        setData(response);
      })
      .catch(error => console.log(error));
  }, []);

  const [formBody, setformBody] = useState({});

  const handleFormChange = (e) => {
        const updatedFormBody = {[e.target.name] : e.target.value}  
        setData( data => ({
        ...data,
        ...updatedFormBody,
        }));
        setformBody( formBody => ({
            ...formBody,
            ...updatedFormBody,
        }));
    }

    const [showResponse, setShowResponse] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        var userdata = JSON.parse(sessionStorage.getItem('user_data'));
        formBody.updated_by = userdata.name;
        axios.post('https://api.petersonslab.id/api/promo-card', formBody)
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/promocard';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
    }

  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event='Promo Card Updated' response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Promo Card' user='Diana Prince'/>
        <Row className='dashboard-content'>
          <Col md={9}>
            <Form onSubmit={handleFormSubmit}>
              <Row>
                <Col>
                  <h4 className='content-header'>Edit Content</h4>
                </Col>
                <Col className='content-header-btn-action'>
                  <Button type='submit' variant='warning' className='header-btn-action-change'>Save Changes</Button>
                </Col>
              </Row>
              <div className='content-form'>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Title</Form.Label>
                  <Form.Control type="text" name="title" placeholder="Add Promo Card Title" value={data.title} onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Content</Form.Label>
                  <Form.Control type="text" name="content" placeholder="Add Promo Card Content" value={data.content} onChange={handleFormChange}/>
                </Form.Group>
              </div>
            </Form>
          </Col>
          <Col md={3}>
            <Status dataid={data.id} created={data.created_at} updated={data.updated_at} updatedby={data.updated_by} ispublished={data.is_published}/>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
