import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import ModalResponse from '../../components/modal-response'

import {Row, Col, Button, Form} from 'react-bootstrap'
import imageCompression from 'browser-image-compression';
import { useEffect, useState, useRef} from 'react'
import axios from 'axios'
import moment from 'moment'

import EditIcon from '../../assets/icon-pencil.svg'

const Home = () => {
  const [data, setData] = useState([]);
  const hiddenFileInput = useRef([]);

  useEffect(() => {
    axios.get(`https://api.petersonslab.id/api/socialmedia`)
    .then(response => {
        setData(response.data);
    })
    .catch(error => console.log(error));
  }, []);

    const [showResponse, setShowResponse] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

    const handleUploadImage = async (e) => {
        const id = e.target.getAttribute('data-id');
        const image = e.target.files[0];
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(image, options);
            var base64files = await convertToBase64(compressedFile);

            var userdata = JSON.parse(sessionStorage.getItem('user_data'));
            var formBody = {
                image: base64files,
                updated_by: userdata.name
            };

            axios.put('https://api.petersonslab.id/api/social-media/'+id, formBody)
            .then(response => {
                setShowResponse(true);
                setResponseMessage('success');
                window.setTimeout(function() {
                    window.location = '/socialmedia';
                }, 1000);
            })
            .catch(errors => {
                setShowResponse(true);
                setResponseMessage('error');
            })
        } catch (error) {
            console.log(error);
        }
    }

  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        <Header page='Social Media' user='Diana Prince'/>
        {
            showResponse ? (
                <ModalResponse event='Social Media Updated' response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Row className='dashboard-content'>
          <Col md={12}>
              <Row>
                <Col>
                  <h4 className='content-header'>Edit Content</h4>
                </Col>
              </Row>
          </Col>
        </Row>
        <div className='row-data'>
          <div className='row-header mb-4'>
            <Row>
                <Col md={1}>ID</Col>
                <Col>Image</Col>
                <Col>Created Date</Col>
                <Col>Last Modified</Col>
                <Col>Updated By</Col>
                <Col md={1} className='row-btn-section'>&nbsp;</Col>
            </Row>
          </div>
          <div className='row-list'>
          {data.map((social, index) => (
            <Row key={index} className={`mb-3 row-list-per-data`}>
              <Col md={1}>{social.id}</Col>
              <Col><img className='row-data-image' src={'https://api.petersonslab.id/socialmedia/'+social.image} /></Col>
              <Col>{moment(social.created_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col>{moment(social.updated_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col>{social.updated_by}</Col>
              <Col md={1} className='row-btn-section'>
                  <Button className='btn-action-index' onClick={() => hiddenFileInput.current[index].click()}><img src={EditIcon} /></Button>
                  <input data-id={social.id} ref={(element) => hiddenFileInput.current[index] = element} type="file" hidden onChange={handleUploadImage}/>
              </Col>
            </Row>
          ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
