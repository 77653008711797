import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import Status from '../../components/status'
import ModalResponse from '../../components/modal-response'

import { useState, useEffect } from 'react'
import {Row, Col, Button, Form} from 'react-bootstrap'
import imageCompression from 'browser-image-compression';
import { useParams } from 'react-router-dom'

import IconPlus from '../../assets/icon-plus.svg'
import IconRemove from '../../assets/icon-remove.svg'
import axios from 'axios'

const Edit = () => {
  const [product, setProduct] = useState({});
  const { id } = useParams();

  useEffect(() => {
    fetch(
      `https://api.petersonslab.id/api/product/full/`+id,
      {
        method: "GET",
      }
      )
      .then(res => res.json())
      .then(response => {
        setProduct(response);
      })
      .catch(error => console.log(error));
  }, []);

  const [sizeInputCount, setSizeInputCount] = useState([]);
  const [sizeInput, setSizeInput] = useState([]);

  const handleClickAddSize = () => {
    if (sizeInputCount.length === 4) {
      return
    };
    setSizeInputCount([...sizeInputCount, sizeInputCount.length]);
  };

  const handleAddSize = (e) => {
    var index = e.currentTarget.getAttribute('data-index');
    var newSize = sizeInput;
    if(newSize[index] != "") {
      newSize[index] = e.target.value;
    } else {
      newSize.push(e.target.value);
    }

    setSizeInput(newSize);
    setformBody( formBody => ({
      ...formBody,
      ...{size : sizeInput},
    }));
  }

  const [formBody, setformBody] = useState({});

  const handleFormChange = (e) => {
    const updatedFormBody = {[e.target.name] : e.target.value}  
    setProduct( product => ({
      ...product,
      ...updatedFormBody,
    }));
    setformBody( formBody => ({
        ...formBody,
        ...updatedFormBody,
    }));
  }

    const [files, setFiles] = useState([]);
    const [base64files, setBase64Files] = useState([]);
    const fileObj = [];
    const [fileArray, setFileArray] = useState([]);

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    const handleUploadMultipleImage = async (e) => {
      fileObj.push(e.target.files)
      var newFileArray = fileArray;
      for (let i = 0; i < fileObj[0].length; i++) {
        const imageFile = fileObj[0][i];
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);
          newFileArray.push(URL.createObjectURL(imageFile));

          const base64 = await convertToBase64(compressedFile);
          base64files.push(base64);

        } catch (error) {
          console.log(error);
        }
      };
      setFiles(newFileArray);
      setformBody( formBody => ({
        ...formBody,
        ...{image : base64files},
      }));
    };

    const [showResponse, setShowResponse] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handleFormSubmit = (e) => {
      e.preventDefault();
      setSubmitting(true);
      console.log(id);
      console.log(formBody);
        axios.put('https://api.petersonslab.id/api/product/'+id, formBody)
        .then( res => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
              window.location = '/products';
          }, 1000);
        })
        .catch( e => {
          setShowResponse(true);
          setResponseMessage('error');
        })
    };

    const[categories, setCategories] = useState([]);

    useEffect(() => {
      fetch(
        `https://api.petersonslab.id/api/category`,
        {
          method: "GET",
        }
        )
        .then(res => res.json())
        .then(response => {
          setCategories(response);
        })
        .catch(error => console.log(error));
  }, []);

  const[submitting, setSubmitting] = useState(false);

  const handleDeleteImage = (e) => {
    var index = e.currentTarget.getAttribute('data-index');
    var newFiles = files;
    newFiles.splice(index, 1);
    setFiles(newFiles);

    var newBase64Files = base64files;
    newBase64Files.splice(index, 1);
    setBase64Files(newBase64Files);

    setformBody( formBody => ({
      ...formBody,
      ...{image : base64files},
    }));
  }

  const[deleteExistingImage, setDeleteExistingImage] = useState([]);

  const handleDeleteExistingImage = (e) => {
    var index = e.currentTarget.getAttribute('data-index');
    var id = e.currentTarget.getAttribute('data-id');
    product.image.splice(index, 1);
    setProduct(product);

    var newDeleteExistingImage = deleteExistingImage;
    newDeleteExistingImage.push(id);
    setDeleteExistingImage(newDeleteExistingImage);

    setformBody( formBody => ({
      ...formBody,
      ...{delete_image : deleteExistingImage},
    }));
  }

  const[deleteExistingSize, setDeleteExistingSize] = useState([]);

  const handleDeleteExistingSize = (e) => {
    var index = e.currentTarget.getAttribute('data-index');
    var id = e.currentTarget.getAttribute('data-id');
    product.size.splice(index, 1);
    setProduct(product);

    var newDeleteExistingSize = deleteExistingSize;
    newDeleteExistingSize.push(id);
    setDeleteExistingSize(newDeleteExistingSize);

    setformBody( formBody => ({
      ...formBody,
      ...{delete_size : deleteExistingSize},
    }));
  }

  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event='Product Updated' response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Product Entry' user='Diana Prince'/>
        <Row className='dashboard-content'>
          <Col md={9}>
          <Form onSubmit={handleFormSubmit}>
              <Row>
                <Col>
                  <h4 className='content-header'>Edit Product</h4>
                </Col>
                <Col className='content-header-btn-action'>
                  <Button type='submit' variant='warning' className='header-btn-action-change' disabled={submitting}>{submitting ? 'Saving...' : 'Save Changes'}</Button>
                </Col>
              </Row>
              <div className='content-form'>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Name</Form.Label>
                  <Form.Control type="text" placeholder="Add Product Name" name='name' value={product.name} onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Category</Form.Label>
                  <Form.Select aria-label="Default select example" name='id_category' onChange={handleFormChange}>
                    <option>Select Category</option>
                    {
                      categories.map((category, index) => (
                        <option value={category.id} key={index}>{category.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Skin Care Effect</Form.Label>
                  <Form.Control as="textarea" rows={5} name='skin_care_effect' value={product.skin_care_effect} onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Core Ingredients</Form.Label>
                  <Form.Control as="textarea" rows={5} name='core_ingredients' value={product.core_ingredients} onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Size</Form.Label>
                  <Row>
                  {
                    product.size ? (
                      product.size.map((size, index) => (
                        <Col md={2} key={index} className='size-existing-section'>
                          <p className='size-existing'>{size.size}</p>
                          <Button data-index={index} data-id={size.id} className='delete-existing-size'  onClick={handleDeleteExistingSize}><img src={IconRemove} className='delete-preview-btn'/></Button>
                        </Col>
                      ))
                    ):(null)
                  }
                  {
                    sizeInputCount.map((input, key) => (
                      <Col md={2} key={key}>
                        <Form.Control data-index={key} name='size' className='size-input-field' type="text" placeholder="Add Size" onChange={handleAddSize}/>
                      </Col>
                    ))
                  }
                  <Col>
                    <Button className='add-size-btn' onClick={handleClickAddSize}><img src={IconPlus} /></Button>
                  </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Upload Image<span>(Max File Size: 5MB)</span></Form.Label>
                  <Row> 
                  {
                    product.image ? (
                      product.image.map((file, index) => (
                      <Col md={2} key={index}>
                        <img src={'https://api.petersonslab.id/product/'+file.image} alt="..." className='upload-image-preview'/>
                        <Button data-index={index} data-id={file.id} className='delete-existing-image-preview' onClick={handleDeleteExistingImage}><img src={IconRemove} className='delete-preview-btn'/></Button>
                      </Col>
                    ))
                    ):(null)
                  }
                  {
                    files.map((file, key) => (
                      <Col md={2} key={key}>
                        <img src={file} alt="..." className='upload-image-preview'/>
                        <Button data-index={key} className='delete-existing-image-preview' onClick={handleDeleteImage}><img src={IconRemove} className='delete-preview-btn'/></Button>
                      </Col>
                    ))
                  }
                  <Col>
                  <label htmlFor='form-image' className='label-image'>
                      <img src={IconPlus} />
                  </label>
                  </Col>
                  </Row>
                  <input id='form-image' type="file" hidden multiple onChange={handleUploadMultipleImage}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Video<span>(If any)</span></Form.Label>
                  <Form.Control type="text" placeholder="Add Youtube Link Video" name='video' value={product.video} onChange={handleFormChange}/>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                      <Form.Label className='content-form-label'>Price</Form.Label>
                      <Form.Control type="text" placeholder="Add Price" name='price' value={product.price} onChange={handleFormChange}/>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                      <Form.Label className='content-form-label'>Pricestrike<span>(optional)</span></Form.Label>
                      <Form.Control type="text" placeholder="Add Pricestrike" name='price_strike' value={product.price_strike} onChange={handleFormChange}/>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>How to Use</Form.Label>
                  <Form.Control as="textarea" rows={6} name='how_to_use' value={product.how_to_use} onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Pair It With</Form.Label>
                  <Form.Control as="textarea" rows={5} name='refuses_to_add' value={product.refuses_to_add} onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Full Ingredient</Form.Label>
                  <Form.Control as="textarea" rows={7} name='full_ingredients' value={product.full_ingredients} onChange={handleFormChange}/>
                </Form.Group>
              </div>
            </Form>
          </Col>
          <Col md={3}>
            <Status created={product.created_at} updated={product.updated_at} dataid={product.id} updatedby={product.updated_by} ispublished={product.is_published}/>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Edit;