import moment from 'moment'
import {Row, Col} from 'react-bootstrap'
import React, { useEffect, useState, useMemo } from "react";

import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import Pagination from '../../components/pagination'

let PageSize = 8;

const Home = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    fetch(
      `https://api.petersonslab.id/api/customers`,
      {
        method: "GET",
      }
      )
      .then(res => res.json())
      .then(response => {
        setData(response.data);
        setTotal(response.total);
        setCurrentData(response.data.slice(0, PageSize));
        setCurrentPage(1);
      })
      .catch(error => console.log(error));
  }, []);

  useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentData(data.slice(firstPageIndex, lastPageIndex));
  }, [currentPage]);
  
  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        <Header page='Users' user='Diana Prince'/>
        <div className='dashboard-content'>
            <h4 className='content-header'>Registered Users</h4>
        </div>
        <div className='row-data-cust'>
            <div className='data-total-section'>
                <p>Total : {total} Users</p>
            </div>
          <div className='row-header mb-4'>
            <Row>
                <Col md={2}>Name</Col>
                <Col>Email</Col>
                <Col>Created Date</Col>
            </Row>
          </div>
          <div className='row-list'>
          {currentData.map((cust, index) => (
            <Row key={index} className='mb-3 row-list-per-data'>
              <Col md={2}>{cust.name}</Col>
              <Col>{cust.email}</Col>
              <Col>{moment(cust.created_at).format("D MMM YYYY - h:mm A")}</Col>
            </Row>
          ))}
          </div>
        </div>
        <Pagination
          className="pagination-bar pb-4 justify-content-end pe-4"
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </>
  );
}

export default Home;
