import moment from 'moment'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import {Row, Col} from 'react-bootstrap'
import React, { useEffect, useState } from "react";


const Home = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(
      `https://api.petersonslab.id/api/newsletter`,
      {
        method: "GET",
      }
      )
      .then(res => res.json())
      .then(response => {
        setData(response);
      })
      .catch(error => console.log(error));
  }, []);
  
  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        <Header page='Newsletter' user='Diana Prince'/>
        <div className='dashboard-content'>
            <h4 className='content-header'>Newsletter Subscriber</h4>
        </div>
        <div className='row-data'>
          <div className='row-header mb-4'>
            <Row>
                <Col md={2}>ID</Col>
                <Col>Email</Col>
                <Col>Created Date</Col>
            </Row>
          </div>
          <div className='row-list'>
          {data.map((newsletter, index) => (
            <Row key={index} className='mb-3 row-list-per-data'>
              <Col md={2}>{newsletter.id}</Col>
              <Col>{newsletter.email}</Col>
              <Col>{moment(newsletter.created_at).format("D MMM YYYY - h:mm A")}</Col>
            </Row>
          ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
