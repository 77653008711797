import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import Status from '../../components/status'
import ModalResponse from '../../components/modal-response'
import {Row, Col, Button, Form} from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'


const Edit = () => {
  const [category, setCategory] = useState({});
  const { id } = useParams();

  useEffect(() => {
    fetch(
      `https://api.petersonslab.id/api/category/`+id,
      {
        method: "GET",
      }
      )
      .then(res => res.json())
      .then(response => {
        setCategory(response);
      })
      .catch(error => console.log(error));
  }, []);

  const [formBody, setformBody] = useState({});

    const handleFormChange = (e) => {
        const updatedFormBody = {[e.target.name] : e.target.value}  
        setCategory( category => ({
          ...category,
          ...updatedFormBody,
        }));
        setformBody( formBody => ({
            ...formBody,
            ...updatedFormBody,
        }));
    }

    const [showResponse, setShowResponse] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        var userdata = JSON.parse(sessionStorage.getItem('user_data'));
        formBody.updatedBy = userdata.name;
        axios.put('https://api.petersonslab.id/api/category/'+category.id, formBody)
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/category';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
    }

  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event='Category Updated' response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Product Category' user='Diana Prince'/>
        <Row className='dashboard-content'>
          <Col md={9}>
            <Form onSubmit={handleFormSubmit}>
              <Row>
                <Col>
                  <h4 className='content-header'>Edit Category</h4>
                </Col>
                <Col className='content-header-btn-action'>
                  <Button type='submit' variant='warning' className='header-btn-action-change'>Save Changes</Button>
                </Col>
              </Row>
              <div className='content-form'>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Name</Form.Label>
                  <Form.Control type="text" placeholder="Add Category Name" value={category.name} name='name' onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Description</Form.Label>
                  <Form.Control as="textarea" rows={5} value={category.desc} name='desc' onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Video<span>(If any)</span></Form.Label>
                  <Form.Control type="text" placeholder="Add Youtube Link Video" value={category.video} name='video' onChange={handleFormChange}/>
                </Form.Group>
              </div>
            </Form>
          </Col>
          <Col md={3}>
            <Status created={category.created_at} updated={category.updated_at} dataid={category.id} updatedby={category.updated_by} ispublished={category.is_published}/>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Edit;