import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import Status from '../../components/status'
import ModalResponse from '../../components/modal-response'

import {Row, Col, Button, Form} from 'react-bootstrap'
import imageCompression from 'browser-image-compression';
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import IconPlus from '../../assets/icon-plus.svg'

const Home = () => {
  const [data, setData] = useState({});
  const { id } = useParams();

  useEffect(() => {
    fetch(
      `https://api.petersonslab.id/api/home-banner/`+id,
      {
        method: "GET",
      }
      )
      .then(res => res.json())
      .then(response => {
        setData(response);
      })
      .catch(error => console.log(error));
  }, []);

  const [formBody, setformBody] = useState({});

  const handleFormChange = (e) => {
    const updatedFormBody = {[e.target.name] : e.target.value}  
    setData( data => ({
    ...data,
    ...updatedFormBody,
    }));
    setformBody( formBody => ({
        ...formBody,
        ...updatedFormBody,
    }));
}

const [showResponse, setShowResponse] = useState(false);
const [responseMessage, setResponseMessage] = useState('');

const handleFormSubmit = (e) => {
    e.preventDefault();
    var userdata = JSON.parse(sessionStorage.getItem('user_data'));
    formBody.updated_by = userdata.name;
    
    axios.put('https://api.petersonslab.id/api/home-banner/'+id, formBody)
    .then(response => {
        setShowResponse(true);
        setResponseMessage('success');
        window.setTimeout(function() {
            window.location = '/banner';
        }, 1000);
    })
    .catch(errors => {
        setShowResponse(true);
        setResponseMessage('error');
    })
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const [files, setFiles] = useState('');

  const handleUploadImage = async (e) => {
      var base64files = '';

      const image = e.target.files[0];
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(image, options);
        var fileUrl = URL.createObjectURL(image)
        setFiles(fileUrl);

        base64files = await convertToBase64(compressedFile);
      } catch (error) {
        console.log(error);
      }
      
      setformBody( formBody => ({
        ...formBody,
        ...{image : base64files},
      }));
  }

  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        <Header page='Home Banner' user='Diana Prince'/>
        {
            showResponse ? (
                <ModalResponse event='Banner Updated' response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Row className='dashboard-content'>
          <Col md={9}>
            <Form onSubmit={handleFormSubmit}>
              <Row>
                <Col>
                  <h4 className='content-header'>Edit Content</h4>
                </Col>
                <Col className='content-header-btn-action'>
                  <Button type='submit' variant='warning' className='header-btn-action-change'>Save Changes</Button>
                </Col>
              </Row>
              <div className='content-form'>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Upload Image<span>(Max File Size: 5MB)</span></Form.Label>
                  <label htmlFor='form-image' className='label-home-banner'>
                      {
                        files != '' ? (
                          <img src={files} alt='...' className='label-home-banner-img'/>
                        )
                        :(
                          <img src={'https://api.petersonslab.id/banner/'+data.image} className='label-home-banner-img'/>
                        )
                      }
                      <img src={IconPlus} className='label-banner-hover-edit'/>
                  </label>
                  <input id='form-image' type="file" hidden onChange={handleUploadImage}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Link<span>(If any)</span></Form.Label>
                  {
                    data.link != "" ? (
                      <Form.Control name='link' type="text" placeholder="Add Banner Link Url" value={data.link} onChange={handleFormChange}/>
                    ) : (
                      <Form.Control name='link' type="text" placeholder="Add Banner Link Url" onChange={handleFormChange}/>
                    )
                  }
                </Form.Group>
              </div>
            </Form>
          </Col>
          <Col md={3}>
            <Status dataid={data.id} created={data.created_at} updated={data.updated_at} updatedby={data.updated_by} ispublished={data.is_published}/>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
