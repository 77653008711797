import {Row, Col, Button} from 'react-bootstrap'
import React, { useEffect, useState, useMemo } from "react"
import moment from 'moment'
import axios from 'axios'

import Sidebar from '../../../components/sidebar'
import Header from '../../../components/header'
import ModalResponse from '../../../components/modal-response'
import Pagination from '../../../components/pagination'

import EditIcon from '../../../assets/icon-pencil.svg'

let PageSize = 5;

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [publishedStatus, setPublishedStatus] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    fetch(
      `https://api.petersonslab.id/api/article/post`,
      {
        method: "GET",
      }
      )
      .then(res => res.json())
      .then(response => {
        setPosts(response);
        setCurrentData(response.slice(0, PageSize));
      })
      .catch(error => console.log(error));
      setCurrentPage(1);
  }, []);

  useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentData(posts.slice(firstPageIndex, lastPageIndex));
  }, [currentPage]);

  const handleSelectBox = (e) => {
    const { id, checked } = e.target;
    if (id === "allSelect") {
      let tempPosts = currentData.map((post) => {
        post.is_published == 1 ? setPublishedStatus(true) : setPublishedStatus(false);
        return { ...post, isChecked: checked };
      });
      setCurrentData(tempPosts);
    } else {
      e.target.getAttribute('published') == 1 ? setPublishedStatus(true) : setPublishedStatus(false);
      let tempPosts = currentData.map((post) =>
      post.id == id ? { ...post, isChecked: checked } : post
      );
      setCurrentData(tempPosts);
    }
  };

  const [showResponse, setShowResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseEvent, setResponseEvent] = useState('');

  const handlePublish = (e) => {
      const publishBody = {};
      const selectedId = [];

      currentData.map((post) => {
          if(post.isChecked) {
            selectedId.push(post.id)
          }
      });
      
      if(e.target.getAttribute('action') == 'publish') {
        setResponseEvent('Published Posts');
        publishBody.data = selectedId;
        publishBody.published = 1;
      } else {
        setResponseEvent('Unpublished Posts');
        publishBody.data = selectedId;
        publishBody.published = 0;
      }
      var userdata = JSON.parse(sessionStorage.getItem('user_data'));
      publishBody.updatedBy = userdata.name;
      axios.post('https://api.petersonslab.id/api/article/post/publish', publishBody)
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/article/post';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
  };

  const handleDelete = () => {
    const selectedId = [];
    const deleteBody = {};
    
    currentData.map((post) => {
        if(post.isChecked) {
          selectedId.push(post.id)
        }
    });

    setResponseEvent('Deleted Posts');
    deleteBody.data = selectedId;
    var userdata = JSON.parse(sessionStorage.getItem('user_data'));
    deleteBody.updatedBy = userdata.name;
    axios.post('https://api.petersonslab.id/api/article/post/delete', deleteBody)
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/article/post';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
  };
  
  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event={responseEvent} response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Article Posts' user='Diana Prince'/>
        <div className='dashboard-content'>
          <Row>
            <Col><h4 className='content-header'>Article Posts</h4></Col>
              {currentData.some((post) => post?.isChecked)?(
                <Col className='content-header-btn-action'>
                  {
                    publishedStatus ? (
                      <Button variant='secondary' className='me-3 header-btn-action-publish' onClick={handlePublish} action='unpublish'>Unpublish</Button>
                    ) : (
                      <Button variant='secondary' className='me-3 header-btn-action-publish' onClick={handlePublish} action='publish'>Publish</Button>
                    )
                  }
                  <Button variant='warning' className='me-3 header-btn-action-change' onClick={handleDelete}>Delete</Button>
                </Col>
              ) : (
                <Col className='content-header-btn-action'>
                 <a href='/article/post/add'><Button variant='warning' className='me-3 header-btn-action-change'>Add</Button></a>
                </Col>
              )}
          </Row>
        </div>
        <div className='row-data'>
          <div className='row-header mb-4'>
            <Row>
                <Col md={1}><input
                type="checkbox"
                className={`${currentData.every((post) => post?.isChecked)? 'checkbox-checked' : '' } form-check-input`}
                id="allSelect"
                checked={!currentData.some((post) => post?.isChecked !== true)}
                onChange={handleSelectBox}
                /></Col>
                <Col>Title</Col>
                <Col>Created Date</Col>
                <Col>Last Modified</Col>
                <Col md={1}>Status</Col>
                <Col md={1} className='row-btn-section'>&nbsp;</Col>
            </Row>
          </div>
          <div className='row-list'>
          {currentData.length != 0 ? currentData.map((post, index) => (
            <Row className={`${post?.isChecked ? 'row-checked' : ''} mb-3 row-list-per-data`} 
            key={index}>
              <Col md={1}><input
              type="checkbox"
              className={`${post?.isChecked ? 'checkbox-checked' : ''} form-check-input checkbox-per-data`}
              id={post.id}
              published={post.is_published}
              checked={post?.isChecked || false}
              onChange={handleSelectBox}
              /></Col>
              <Col>{post.title}</Col>
              <Col>{moment(post.created_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col>{moment(post.updated_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col md={1}>{
              post.is_published == 1 ? 'Published' : 'Unpublished'
              }</Col>
              <Col md={1} className='row-btn-section'><a href={'/article/post/edit/' + post.id}><img src={EditIcon} /></a></Col>
            </Row>
          )): (
            <>
                <center><p>There is no data</p></center>
            </>
          )}
          </div>
        </div>
        <Pagination
          className="pagination-bar pb-4 justify-content-end pe-4"
          currentPage={currentPage}
          totalCount={posts.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </>
  );
}

export default Home;
